import {
  Column,
  DataTypeProvider,
  Sorting,
  SortingState,
  TableColumnWidthInfo,
  VirtualTableState,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid as DxGrid,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableHeaderRow,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import clsx from "clsx";
import { ComponentType, useCallback, useEffect, useMemo, useState } from "react";
import { PaginatedGridState } from "../../../hooks/paginatedGridState";
import "./PaginatedGrid.css";

export type ColumnsFormatting = {
  dates: {
    columns: string[];
    formatterComponent: ComponentType<DataTypeProvider.ValueFormatterProps>;
  };
  numeric: {
    columns: string[];
    formatterComponent: ComponentType<DataTypeProvider.ValueFormatterProps>;
  };
};

interface Props {
  columns: Column[];
  columnsFormatting?: ColumnsFormatting;
  sorting: Sorting[];
  columnsAlignment: VirtualTable.ColumnExtension[];
  state: PaginatedGridState;
  supportDrillDown: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRowId: (row: any) => string | number;
  getRemoteRows: (requestedSkip: number, take: number) => void;
  loadData: () => void;
  onColumnsOrderChange: (names: string[]) => void;
  onColumnsSortingChange: (newSorting: Sorting[]) => void;
  onCellClicked?: (row: Record<string, string>, columnName: string) => void;
}

export default function PaginatedGrid(props: Props) {
  const {
    columns,
    columnsFormatting,
    sorting,
    columnsAlignment,
    state,
    supportDrillDown,
    getRowId,
    getRemoteRows,
    loadData,
    onColumnsOrderChange,
    onColumnsSortingChange,
    onCellClicked,
  } = props;

  const { rows, skip, totalCount, loading } = state;

  useEffect(() => {
    loadData();
  });

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>(
    columns.map((c) => ({ columnName: c.name, width: 170 }))
  );

  const actualColumnWidth = useMemo(() => {
    return columns.map((column) => {
      const existSizeColumn = columnWidths.find((cw) => cw.columnName === column.name);
      if (existSizeColumn === undefined) return { columnName: column.name, width: 170 };
      return existSizeColumn;
    });
  }, [columns, columnWidths]);
  const columnOrder = useMemo(() => {
    return columns.map((c) => c.name);
  }, [columns]);

  const Cell = useCallback(
    (props: Table.DataCellProps) => {
      return (
        <VirtualTable.Cell
          {...props}
          style={{
            paddingTop: "3px",
            paddingBottom: "3px",
            height: "32px",
            fontSize: "13px",
          }}
          onClick={() => supportDrillDown && onCellClicked?.call(null, props.row, props.column.name)}
        />
      );
    },
    [supportDrillDown, onCellClicked]
  );

  const Row = useCallback(
    (props: Table.DataRowProps) => {
      return <VirtualTable.Row {...props} className={clsx({ "paginated-grid-row": supportDrillDown })} />;
    },
    [supportDrillDown]
  );

  return (
    <DxGrid rows={rows} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
      <DataTypeProvider
        for={columnsFormatting?.dates?.columns || []}
        formatterComponent={columnsFormatting?.dates?.formatterComponent}
      />
      <DataTypeProvider
        for={columnsFormatting?.numeric?.columns || []}
        formatterComponent={columnsFormatting?.numeric?.formatterComponent}
      />
      <VirtualTableState
        infiniteScrolling={false}
        loading={loading}
        totalRowCount={totalCount}
        pageSize={100}
        skip={skip}
        getRows={getRemoteRows}
      />
      <SortingState sorting={sorting} onSortingChange={onColumnsSortingChange} />
      <DragDropProvider />
      <VirtualTable
        columnExtensions={columnsAlignment}
        cellComponent={Cell}
        rowComponent={Row}
        height="1000px"
        estimatedRowHeight={36}
      />
      <TableColumnResizing
        resizingMode="widget"
        columnWidths={actualColumnWidth}
        onColumnWidthsChange={setColumnWidths}
      />
      <TableColumnReordering order={columnOrder} onOrderChange={onColumnsOrderChange} />
      <TableHeaderRow cellComponent={HeaderCell} showSortingControls />
    </DxGrid>
  );
}

function RootComponent(props: DxGrid.RootProps) {
  return (
    <DxGrid.Root
      {...props}
      sx={{
        width: "inherit",
        ".TableContainer-root": { border: "1px solid #E5E6E8" },
        ".MuiTableCell-head.TableStubCell-cell": { backgroundColor: "rgb(245, 245, 245)" },
      }}
    />
  );
}

function HeaderCell(props: TableHeaderRow.CellProps) {
  return (
    <TableHeaderRow.Cell
      {...props}
      style={{
        paddingTop: "4px",
        paddingBottom: "4px",
        height: "34px",
        fontSize: "13px",
        fontWeight: 500,
        backgroundColor: "rgb(245, 245, 245)",
      }}
    />
  );
}

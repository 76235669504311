import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportType } from "../../shared/reporting/api/biClient.types";
import {
  ChartOfAccounts,
  ChartOfAccountsItem,
  DimensionDictionary,
  ItemDataType,
  MeasureDescriptor,
  MeasuresStructure,
  MeasureUsage,
  MetaItemType,
} from "../api/biApi.types";
import { RootState } from "./RootState";
import cloneDeep from "../../shared/utilities/cloneDeep";
import { createSelector } from "reselect";
import { MetaDataState } from "./MetaDataState";

const initialState: MetaDataState = {
  dimensionsStructure: {
    dimensions: [],
    groups: [],
  },
  measuresStructure: {
    names: [],
    groups: [],
  },
  dimensions: [],
  measures: [],
  allMeasures: [],
  functions: [],
  measureGroups: [],
  loaded: false,
};

const addToGroup = (measuresStructure: MeasuresStructure, measure: MeasureDescriptor) => {
  const group = measuresStructure.groups.find((g) => g.caption === measure.group);
  if (group !== undefined) {
    group.names.push(measure.name);
  }
};

const removeFromGroup = (measuresStructure: MeasuresStructure, measure: MeasureDescriptor, groupName: string) => {
  const group = measuresStructure.groups.find((g) => g.caption === groupName);
  if (group !== undefined) {
    const index = group.names.indexOf(measure.name);
    if (index > -1) {
      group.names.splice(index, 1);
    }
  }
};

export const metaDataSlice = createSlice({
  name: "metaData",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<MetaDataState>>) => {
      Object.assign(state, action.payload);
      filterMeasures(state);
    },
    addMeasure: (state, action: PayloadAction<MeasureDescriptor>) => {
      const measure = action.payload;
      measure.itemType = MetaItemType.MEASURE;
      state.allMeasures.push(measure);
      state.measures.push(measure);
      addToGroup(state.measuresStructure, measure);
    },
    updateMeasure: (state, action: PayloadAction<MeasureDescriptor>) => {
      const stateMeasure = state.measures.find((m) => m.name === action.payload.name);
      if (stateMeasure !== undefined) {
        const updatedMeasure = Object.assign(stateMeasure, action.payload);
        if (stateMeasure.group !== action.payload.group) {
          removeFromGroup(state.measuresStructure, stateMeasure, stateMeasure.group);
          addToGroup(state.measuresStructure, updatedMeasure);
        }
      }
    },
    updateReportType: (state, action: PayloadAction<ReportType>) => {
      state.reportType = action.payload;
      filterMeasures(state);
    },
    deleteMeasure: (state, action: PayloadAction<MeasureDescriptor>) => {
      const measure = action.payload;
      const index = state.allMeasures.findIndex((m) => m.name === measure.name);
      if (index < 0) return;
      state.allMeasures.splice(index, 1);
      removeFromGroup(state.measuresStructure, measure, measure.group);
      filterMeasures(state);
    },
    updateDictionaries: (state, action: PayloadAction<{ [key: string]: DimensionDictionary }[]>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        const dimension = state.dimensions.find((d) => d.name === key);
        if (dimension !== undefined) {
          dimension.dictionary = cloneDeep(Object.values(value).map((v) => v));
        }
      });
    },
    setChartOfAccounts: (state, action: PayloadAction<ChartOfAccounts>) => {
      state.chartOfAccounts = action.payload;
    },
  },
});

function filterMeasures(state: MetaDataState) {
  if (state.reportType === ReportType.Pivot) {
    state.measures = filterMeasuresByUsage(state.allMeasures, [MeasureUsage.PivotOnly]);
  } else if (state.reportType === ReportType.Tabular) {
    state.measures = filterMeasuresByUsage(state.allMeasures, [MeasureUsage.TabularOnly]);
  } else {
    state.measures = state.allMeasures;
  }
}

function filterMeasuresByUsage(measures: MeasureDescriptor[], expectedUsages: MeasureUsage[]) {
  return measures.filter(
    (measure): measure is MeasureDescriptor =>
      measure.usage === undefined || measure.usage === MeasureUsage.General || expectedUsages.includes(measure.usage)
  );
}

export const metaDataActions = metaDataSlice.actions;

export const selectMetaData = (state: RootState) => state.metaData;
export const selectReportType = (state: RootState) => state.metaData.reportType;
export const selectDimensions = (state: RootState) => state.metaData.dimensions;
export const selectDimensionsStructure = (state: RootState) => state.metaData.dimensionsStructure;
export const selectMeasures = (state: RootState) => state.metaData.measures;
export const selectMeasuresStructure = (state: RootState) => state.metaData.measuresStructure;
export const selectFunctions = (state: RootState) => state.metaData.functions;
export const selectMeasureGroups = (state: RootState) => state.metaData.measureGroups;
export const selectLoaded = (state: RootState) => state.metaData.loaded;
export const selectLoadingFailed = (state: RootState) => state.metaData.loadingFailed;
export const selectChartOfAccountsState = (state: RootState) => state.metaData.chartOfAccounts;
export const selectDimensionDictionary = (name: string | undefined) => (state: RootState) =>
  state.metaData.dimensions.find((d) => d.name === name)?.dictionary;

export const selectDimensionsForCurrentReportType = createSelector(
  selectDimensions,
  selectReportType,
  (dimensions, reportType) => {
    return reportType === ReportType.Tabular
      ? dimensions
      : dimensions.filter((d) => d.type === ItemDataType.Date || d.type === ItemDataType.General);
  }
);

export const selectPostingChartOfAccounts = createSelector(selectChartOfAccountsState, (chartOfAccounts) => {
  if (chartOfAccounts) {
    return {
      gl: chartOfAccounts.gl?.filter(filterPosting) || [],
      memo: chartOfAccounts.memo?.filter(filterPosting) || [],
    } as ChartOfAccounts;
  }
  return undefined;
});

export const selectChartOfAccounts = createSelector(selectChartOfAccountsState, (chartOfAccounts) => {
  if (chartOfAccounts) {
    return {
      gl: chartOfAccounts.gl || [],
      memo: chartOfAccounts.memo || [],
    } as ChartOfAccounts;
  }
  return undefined;
});

function filterPosting(item: ChartOfAccountsItem) {
  return item.accountType === "Posting";
}
export default metaDataSlice.reducer;

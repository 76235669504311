import { Grid, Typography } from "@mui/material";
import React from "react";
import { BuilderContext } from "../../types";
import StyleSwitch from "./StyleSwitch";

export default function TableStyle() {
  const { settingsArea } = React.useContext(BuilderContext);

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        borderBottom: "1px dashed #E0E0E0",
        pb: 1,
        gap: 0.5,
      }}
    >
      <Typography variant="subtitle2" fontWeight={500} color={(theme) => theme.palette.text.primary}>
        Table Style
      </Typography>
      <StyleSwitch
        label="Show Rows Grand Total"
        value={settingsArea.settings.rowsGrandTotal === true}
        onChange={(newValue) => settingsArea.update({ rowsGrandTotal: newValue })}
      />
    </Grid>
  );
}

import { Slider, Stack, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import useDebounce from "../../../../../../shared/hooks/useDebounce";
import { BarSeriesSettings, BarWidth, SeriesSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { isBarSerie, toBarSerie } from "../../contexts/FieldsStateContext.types";
import TypographyInfo from "../TypographyInfo";

const Values: { value: number; label: BarWidth }[] = [
  { value: 10, label: "Thin" },
  { value: 20, label: "Small" },
  { value: 30, label: "Medium" },
  { value: 40, label: "Large" },
];

interface Props {
  area: SettingsArea;
  serie: SeriesSettings;
}

export default function BarWidthItem({ area, serie }: Props) {
  const { chart: locale } = useLocalization();
  const supported = React.useMemo(() => isBarSerie(area.settings, serie), [area.settings, serie]);
  const barSerie = React.useMemo(() => toBarSerie(serie), [serie]);

  const [value, setValue] = React.useState(() => Values.find((v) => v.label === barSerie?.barWidth)?.value || 30);

  const handleBarWidthChanged = useDebounce((value: number) => {
    const newBarWidth = Values.find((v) => v.value === value)?.label;
    if (newBarWidth !== undefined) {
      area.updateSerie<BarSeriesSettings>(serie.name, { barWidth: newBarWidth });
    }
  }, 400);

  React.useEffect(() => {
    handleBarWidthChanged(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const formatValueLabel = React.useCallback((value: number) => {
    return Values.find((v) => v.value === value)?.label;
  }, []);

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center" gap={3}>
      <TypographyInfo tooltip={locale.barwidth_property_explanation}>
        <Typography color={(theme) => theme.palette.text.secondary} sx={{ whiteSpace: "nowrap" }}>
          Bar Width
        </Typography>
      </TypographyInfo>
      <HorizontalFill />
      <Slider
        value={value}
        valueLabelDisplay="auto"
        min={10}
        max={40}
        step={null}
        sx={{ py: 0, mr: 1, width: 120 }}
        marks={Values.map((v) => ({ value: v.value, label: "" }))}
        valueLabelFormat={formatValueLabel}
        onChange={(_, value) => setValue(value as number)}
      />
    </Stack>
  );
}

export function getBarWidth(serie: SeriesSettings | undefined): BarWidth {
  if (!serie) return "Medium";
  return toBarSerie(serie)?.barWidth || "Medium";
}

export function getBarWidthInPixels(serie: SeriesSettings | undefined): number {
  const barWidth = getBarWidth(serie);
  switch (barWidth) {
    case "Thin":
      return 10;
    case "Small":
      return 25;
    case "Medium":
      return 40;
    case "Large":
      return 55;
    default:
      return 25;
  }
}

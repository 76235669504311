import { AnyObject } from "../../shared/types";

const PAGE_SIZE = 100;

export type GridPage = {
  skip: number;
  take: number;
};

export enum PaginatedGridActionType {
  UPDATE_ROWS = "UPDATE_ROWS",
  START_LOADING = "START_LOADING",
  REQUEST_ERROR = "REQUEST_ERROR",
  FETCH_INIT = "FETCH_INIT",
  UPDATE_REQUEST = "UPDATE_REQUEST",
}

export type PaginatedGridUpdateAction = {
  type:
    | PaginatedGridActionType.UPDATE_ROWS
    | PaginatedGridActionType.START_LOADING
    | PaginatedGridActionType.UPDATE_REQUEST
    | PaginatedGridActionType.FETCH_INIT
    | PaginatedGridActionType.REQUEST_ERROR;
  payload: Partial<PaginatedGridState>;
};

export type PaginatedGridState = {
  rows: AnyObject[];
  skip: number;
  requestedSkip: number;
  take: number;
  totalCount: number;
  loading: boolean;
  lastQuery: GridPage | undefined;
};

export const initialState: PaginatedGridState = {
  rows: [],
  skip: 0,
  requestedSkip: 0,
  take: PAGE_SIZE * 2,
  totalCount: 0,
  loading: false,
  lastQuery: undefined,
};

export const reducer = (state: PaginatedGridState, action: PaginatedGridUpdateAction) => {
  switch (action.type) {
    case PaginatedGridActionType.UPDATE_ROWS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case PaginatedGridActionType.START_LOADING:
      return {
        ...state,
        ...action.payload,
      };
    case PaginatedGridActionType.REQUEST_ERROR:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case PaginatedGridActionType.FETCH_INIT:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case PaginatedGridActionType.UPDATE_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

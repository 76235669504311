import { Button, Collapse, Divider, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import objectHash from "object-hash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { DimensionDescriptor, DimensionsStructure } from "../../../../../api/biApi.types";
import { FieldWithOrder, FieldWithSorting } from "../../../../../hooks/FieldWithOrder";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { OptionField } from "./ColumnsOptions.types";
import ColumnsContainer from "./ColumnsContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SortingContainer from "./sorting/SortingContainer";

const isSelectionValid = (selection: FieldWithOrder[]) => {
  return !selection.some((fwo) => !fwo.field.caption);
};

enum TabNames {
  "columns" = "columns",
  "sorting" = "sorting",
}

interface Props {
  open: boolean;
  selectedFields: FieldWithOrder[];
  sortedFields: FieldWithSorting[];
  dimensions: DimensionDescriptor[];
  groupStructure: DimensionsStructure;
  withAllocation: boolean;
  onApplySelection: (selection: FieldWithOrder[]) => void;
  onApplySorting: (sorting: FieldWithSorting[]) => void;
  onClose: () => void;
}

export default function ColumnsOptions(props: Props) {
  const {
    dimensions,
    selectedFields,
    sortedFields,
    open,
    groupStructure,
    withAllocation,
    onApplySelection,
    onApplySorting,
    onClose,
  } = props;

  const { drilldown: locale } = useLocalization();

  const [selectedTab, setSelectedTab] = useState(TabNames.columns);
  const [dirtyColumnsSelection, setDirtyColumnsSelection] = useState<FieldWithOrder[]>(() => cloneDeep(selectedFields));
  const [dirtyColumnsSorting, setDirtyColumnsSorting] = useState<FieldWithSorting[]>(() => cloneDeep(sortedFields));

  const validDimensions = React.useMemo(
    () => (withAllocation ? dimensions : dimensions.filter((d) => d.glAllowed === true)),
    [withAllocation, dimensions]
  );

  useEffect(() => setDirtyColumnsSelection(cloneDeep(selectedFields)), [selectedFields]);
  useEffect(() => setDirtyColumnsSorting(cloneDeep(sortedFields)), [sortedFields]);

  const columnsHasChanged = useMemo(() => {
    const originalFieldsHash = objectHash(selectedFields);
    const dirtySelectionHash = objectHash(dirtyColumnsSelection);
    if (originalFieldsHash === dirtySelectionHash) {
      return false;
    }
    return isSelectionValid(dirtyColumnsSelection);
  }, [selectedFields, dirtyColumnsSelection]);

  const sortingHasChanged = useMemo(() => {
    const originalFieldsHash = objectHash(sortedFields);
    const dirtySelectionHash = objectHash(dirtyColumnsSorting);
    if (originalFieldsHash === dirtySelectionHash) {
      return false;
    }
    return isSelectionValid(dirtyColumnsSorting);
  }, [sortedFields, dirtyColumnsSorting]);

  const options = useMemo(() => {
    const getGroupName = (field: DimensionDescriptor) => {
      const group = groupStructure.groups.find((g) => g.dimensions.indexOf(field.name) > -1);
      return group?.caption || "General";
    };
    return validDimensions
      .map((f) => ({ id: f.name, label: f.caption, field: f, group: getGroupName(f) }) as OptionField)
      .sort((a, b) => {
        if (a.group > b.group) return 1;
        if (a.group < b.group) return -1;
        return 0;
      });
  }, [validDimensions, groupStructure]);

  const applySelection = useCallback(() => {
    if (columnsHasChanged) {
      dirtyColumnsSelection.forEach((field, index) => {
        field.order = index;
      });
      onApplySelection(cloneDeep(dirtyColumnsSelection));
    }
    if (sortingHasChanged) {
      dirtyColumnsSorting.forEach((field, index) => {
        field.order = index;
      });
      onApplySorting(cloneDeep(dirtyColumnsSorting));
    }
    onClose();
  }, [
    dirtyColumnsSelection,
    dirtyColumnsSorting,
    columnsHasChanged,
    sortingHasChanged,
    onApplySelection,
    onApplySorting,
    onClose,
  ]);

  const closeDialog = useCallback(() => {
    setDirtyColumnsSelection(cloneDeep(selectedFields));
    onClose();
  }, [selectedFields, onClose]);

  return (
    <Collapse
      in={open}
      orientation="horizontal"
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        height: "100%",
        borderRadius: 0,
        zIndex: 500,
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flex: 1,
          width: "348px",
          height: "100%",
          borderRadius: 0,
        }}
        elevation={3}
      >
        <Grid container sx={{ flexDirection: "column" }}>
          <Grid container sx={{ px: 3, pt: 2, alignItems: "center" }}>
            <Typography variant="h6">{locale.column_options_label}</Typography>
            <HorizontalFill />
            <CloseIconButton onClick={closeDialog} />
          </Grid>
          <Grid container>
            <Tabs
              sx={{ display: "flex", flex: 1 }}
              variant="fullWidth"
              value={selectedTab}
              onChange={(_, value) => setSelectedTab(value)}
            >
              <Tab label="Columns" value={TabNames.columns} />
              <Tab label="Sorting" value={TabNames.sorting} />
            </Tabs>
          </Grid>
          <Divider />
          <Grid container sx={{ flex: 1, overflowY: "auto", alignItems: "start" }}>
            <DndProvider backend={HTML5Backend}>
              {selectedTab === TabNames.columns && (
                <ColumnsContainer
                  selectedFields={dirtyColumnsSelection}
                  options={options}
                  onSelectionChanged={setDirtyColumnsSelection}
                />
              )}
              {selectedTab === TabNames.sorting && (
                <SortingContainer
                  selectedFields={dirtyColumnsSorting}
                  options={options}
                  onSelectionChanged={setDirtyColumnsSorting}
                />
              )}
            </DndProvider>
          </Grid>

          <Divider />
          <Grid container sx={{ gap: 1, px: 2, py: 2 }}>
            <HorizontalFill />
            <Button variant="text" color="secondary" onClick={closeDialog}>
              Cancel
            </Button>
            <Button variant="contained" disabled={!columnsHasChanged && !sortingHasChanged} onClick={applySelection}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Collapse>
  );
}

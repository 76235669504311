import {
  GridAutosizeOptions,
  GridColDef,
  GridColumnGroup,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useEffect, useMemo, useRef } from "react";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { isPinnedRowNode } from "../../../../../shared/components/grid/gridHelper";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import {
  PivotDataGrandTotals,
  PivotRowDimensionCell,
  PivotRowMeasureCell,
  PivotRowResponse,
} from "../../../../api/biApi.types";
import { GeneralField } from "../../Types";
import { createGrandTotalLinesCell, createGrandTotalMeasureCell, createLinesCell, createMeasureCell } from "./Helper";

const autosizeOptions: GridAutosizeOptions = {
  includeOutliers: true,
  includeHeaders: true,
};

interface Props {
  rows: PivotRowResponse[];
  columns: GridColDef[];
  columnGroups: GridColumnGroup[];
  size: { width: number; height: number };
  loading: boolean;
  rowFields: GeneralField[];
  grandTotals?: PivotDataGrandTotals;
  showSummaries?: boolean;
  onValueCellClick: (cell: PivotRowMeasureCell | undefined) => void;
}
const PivotGrid = ({
  columnGroups,
  columns,
  loading,
  rowFields,
  rows,
  size,
  onValueCellClick,
  grandTotals,
  showSummaries,
}: Props) => {
  const apiRef = useGridApiRef();
  const rowFieldsRef = useRef(rowFields);
  rowFieldsRef.current = rowFields;

  useEffect(() => {
    setTimeout(() => {
      apiRef.current.autosizeColumns(autosizeOptions);
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const gridColumns = useMemo(
    () =>
      columns.map((c) => {
        if (c.field === "lines") {
          c.renderCell = (params: GridRenderCellParams<GridValidRowModel, PivotRowDimensionCell>) => {
            if (showSummaries && isPinnedRowNode(params.rowNode)) {
              return createGrandTotalLinesCell();
            }
            return createLinesCell(params.value, rowFieldsRef.current);
          };
        } else {
          c.renderCell = (params: GridRenderCellParams<GridValidRowModel, PivotRowMeasureCell>) => {
            if (showSummaries && isPinnedRowNode(params.rowNode)) {
              return createGrandTotalMeasureCell(params?.value?.formattedValue);
            }
            return createMeasureCell(params.value, rowFieldsRef.current);
          };
        }
        return c;
      }),
    [columns, showSummaries]
  );

  const pinnedRows = useMemo((): GridPinnedRowsProp => {
    if (!grandTotals || Object.keys(grandTotals || {}).length === 0) {
      return {};
    }
    return { bottom: [grandTotals] };
  }, [grandTotals]);

  return (
    <DataGrid<PivotRowResponse | PivotDataGrandTotals>
      className="PivotGrid"
      apiRef={apiRef}
      columns={gridColumns}
      columnGroupingModel={columnGroups}
      rows={rows}
      getRowId={getRowId}
      pinnedRows={pinnedRows}
      sx={(t) => ({
        "--DataGrid-rowBorderColor": t.palette.divider,
        maxWidth: size.width,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "divider",
        ".MuiDataGrid-virtualScroller--hasScrollX .MuiDataGrid-columnHeader--last": {
          borderRight: "1px solid var(--DataGrid-rowBorderColor)",
        },
        ".MuiDataGrid-columnHeaders": {
          ".MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer": {
            borderBottom: "none",
          },
          ".MuiDataGrid-columnHeader--filledGroup": {
            borderBottom: "1px solid var(--DataGrid-rowBorderColor)",
          },
          "[role=row]": {
            bgcolor: "#f5f5f5",
            ".MuiDataGrid-columnHeader": {
              bgcolor: "#f5f5f5",
            },
          },
        },
        ".MuiDataGrid-columnSeparator": {
          color: t.palette.divider,
          "&.MuiDataGrid-columnSeparator--resizing": {
            color: t.palette.divider,
          },
        },
        ".MuiDataGrid-pinnedRows--bottom": {
          ".MuiDataGrid-cell--pinnedLeft": {
            background: "inherit",
          },
        },
        ".MuiDataGrid-container--bottom [role=row]": {
          background: "#f5f5f5",
        },
        ".MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop": {
          borderTop: "none",
        },
        ".MuiDataGrid-bottomContainer": {
          bottom: 0,
        },
      })}
      containerSx={{ alignItems: "center" }}
      scrollContainerSx={{ width: size.width }}
      rowHeight={32}
      disableRowSelectionOnClick
      initialState={{ pinnedColumns: { left: ["lines"] } }}
      autosizeOptions={autosizeOptions}
      loading={loading}
      hideFooter
      showCellVerticalBorder
      showColumnVerticalBorder
      columnHeaderHeight={32}
      slots={{ loadingOverlay: InlineLoaderOverlay }}
      slotProps={{ columnHeaders: { style: { backgroundColor: "#f5f5f5" } } }}
      onCellClick={({ field, value }) => {
        if (field === "lines") {
          return;
        }
        onValueCellClick(value as PivotRowMeasureCell);
      }}
    />
  );
};

function getRowId(row: PivotRowResponse) {
  const lines = row["lines"] as PivotRowDimensionCell;
  if (lines === undefined) {
    return generateGuid();
  }

  return lines.rowId;
}

function InlineLoaderOverlay() {
  return <InlineLoader sx={{ bgcolor: "#ffffff82", zIndex: 1 }} overlay />;
}

export default PivotGrid;

import { Tooltip } from "@mui/material";
import React from "react";

interface Props {
  tooltip: string;
  children: JSX.Element;
}

export default function TypographyInfo({ tooltip, children }: Props) {
  return (
    <Tooltip title={tooltip}>
      {React.cloneElement(children, {
        sx: { textDecoration: "underline", textDecorationStyle: "dotted", cursor: "help" },
      })}
    </Tooltip>
  );
}

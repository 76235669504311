import { Slider, Stack, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import useDebounce from "../../../../../../shared/hooks/useDebounce";
import { ReportType } from "../../../../../../shared/reporting/api/biClient.types";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { getDoughnutSettings } from "../../contexts/FieldsStateContext.types";
import TypographyInfo from "../TypographyInfo";

interface Props {
  area: SettingsArea;
}

export default function ThicknessItem({ area }: Props) {
  const { chart: locale } = useLocalization();
  const supported = React.useMemo(() => area.settings.type === ReportType.DoughnutChart, [area.settings]);
  const [value, setValue] = React.useState(() => getThickness(area));

  const handleThicknessChanged = useDebounce((thickness: number) => area.update({ thickness }), 400);

  React.useEffect(() => {
    const invertedValue = 1 - value;
    handleThicknessChanged(invertedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const formatValueLabel = React.useCallback((value: number) => `${(value * 100).toFixed(0)}%`, []);

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center" gap={3}>
      <TypographyInfo tooltip={locale.thickness_property_explanation}>
        <Typography color={(theme) => theme.palette.text.secondary} sx={{ whiteSpace: "nowrap" }}>
          Thickness
        </Typography>
      </TypographyInfo>
      <HorizontalFill />
      <Slider
        value={value}
        valueLabelDisplay="auto"
        min={0.1}
        max={0.9}
        step={0.1}
        sx={{ py: 0, mr: 1, width: 120 }}
        marks={true}
        valueLabelFormat={formatValueLabel}
        onChange={(_, value) => setValue(value as number)}
      />
    </Stack>
  );
}

export function getThickness(area: SettingsArea): number {
  return getDoughnutSettings(area.settings)?.thickness || 0.5;
}

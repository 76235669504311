import { Alert, Grid } from "@mui/material";
import { useDrillDownState } from "../../../../../hooks/drillDownState";
import ReadonlyConditions from "../ReadonlyConditions";
import useDeferredDictionaryLoading from "../../hooks/useDeferredDictionaryLoading";
import { CashFlowGrid } from "./CashFlowGrid";
import ToolBar from "./ToolBar";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import { IrrDrillDownInfo } from "../../../../../api/biApi.types";
import CollapseContainer from "../../../../common/CollapseContainer";
import ConditionsCollapsedHeader from "../ConditionsCollapsedHeader";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";

export interface Props {
  configuration: DrillDownConfiguration;
  info: IrrDrillDownInfo;
}

export default function CashFlowPart({ configuration, info }: Props) {
  const state = useDrillDownState(info);

  useDeferredDictionaryLoading({ values: state.conditions, updateItem: state.actions.updateCondition });

  return (
    <Grid container sx={{ position: "relative", flexDirection: "row" }}>
      <CollapseContainer collapseElement={<ConditionsCollapsedHeader conditionsCount={state.conditions.length} />}>
        <Grid
          sx={(theme) => ({
            flex: 1,
            maxWidth: 340,
            p: "1.3rem",
            pl: 3,
            borderRight: "1px solid " + theme.palette.divider,
          })}
        >
          <ReadonlyConditions state={state} configuration={configuration} info={info} />
        </Grid>
      </CollapseContainer>
      <Grid container sx={{ flex: 1, width: "unset", flexDirection: "column" }}>
        <Grid container sx={{ flex: 1, width: "unset", flexDirection: "column", pl: "1.3rem", py: 2, pr: 3, gap: 2 }}>
          <ToolBar configuration={configuration} info={info} />
          {state.gridState.error && (
            <Alert sx={{ mb: ".5rem" }} severity="error">
              {state.gridState.error}
            </Alert>
          )}
          <ScrollableFlexContainer scrollContainerSx={{ mb: 1 }}>
            <CashFlowGrid configuration={configuration} info={info} />
          </ScrollableFlexContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}

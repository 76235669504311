import { useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NumberFormatterOptions } from "../../../../../../shared/reporting/api/biClient.types";
import biClient from "../../../../../api/biApi";
import { ChartOfAccounts, MeasureDataset, MeasureDescriptor } from "../../../../../api/biApi.types";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { selectMeasureDatasets, update } from "../../../../../store/measureDataSetsSlice";
import {
  metaDataActions,
  selectDimensions,
  selectFunctions,
  selectPostingChartOfAccounts,
} from "../../../../../store/metaDataSlice";
import { ConditionField } from "../../../Types";
import { FormulaNode } from "../utilities/fillNodes";
import { INode, ITokenNode } from "../utilities/formulaParser";
import { isFormulaValid } from "../utilities/formulaValid";
import { toAmountType, toCalculateBy, toFormulaNodes } from "../utilities/measureConverter";
import { Token } from "../utilities/tokenizer";
import { getAvailableDimensions } from "../utilities/variables.utils";
import { CustomMeasureActionType, CustomMeasureReducer, initialState } from "./CustomMeasureReducer";
import { CustomMeasureState } from "./customMeasureState";

export const findNodeByTokenData = (ln: ITokenNode, payload?: Token) =>
  ln.token.endIndex === payload?.endIndex &&
  ln.token.startIndex === payload?.startIndex &&
  ln.token.text === payload?.text &&
  ln.token.tokenType === payload?.tokenType;

export type CustomMeasureReturnType = CustomMeasureState & {
  formulaValid: boolean;
  chartOfAccounts?: ChartOfAccounts;
  actions: {
    update: (changes: Partial<CustomMeasureState>) => void;
    setDataSets: (dataSets: MeasureDataset[]) => void;
    setRootNode: (rootNode?: INode) => void;
    updateFormulaNode: (node: FormulaNode, changes: Partial<FormulaNode>) => void;
    addConditions: (node: FormulaNode, newConditions: ConditionField[]) => void;
    updateCondition: (node: FormulaNode, condition: ConditionField, changes: Partial<ConditionField>) => void;
    removeCondition: (node: FormulaNode, condition: ConditionField) => void;
    highlightNode: (token: Token | undefined) => void;
    functionHovered: (token: Token | undefined) => void;
    setSelectedFormulaParameter: (token: Token | undefined) => void;
  };
  dataLoadingError: string | undefined;
  loading: boolean;
  datasetError: string | undefined;
};
interface Props {
  measure?: MeasureDescriptor;
  defaultGroup?: string;
  measureFormat?: NumberFormatterOptions | undefined;
}
export const useCustomMeasure = ({ measure, defaultGroup, measureFormat }: Props): CustomMeasureReturnType => {
  const dispatch = useDispatch();
  const [state, dispatchCustom] = useReducer(CustomMeasureReducer, initialState);
  const chartOfAccounts = useSelector(selectPostingChartOfAccounts);

  const formulaValid = useMemo(() => {
    return isFormulaValid(state);
  }, [state]);

  const datasetsState = useSelector(selectMeasureDatasets);
  const dimensions = useSelector(selectDimensions);
  const functions = useSelector(selectFunctions);
  const { custom_measure: locale } = useLocalization();
  const dispatchStoreAction = useDispatch();

  const [dataLoadingError, setDataLoadingError] = useState<string>();
  const [datasetError, setDatasetError] = useState<string>();

  useEffect(() => {
    if (datasetsState.loaded) {
      actions.setDataSets(datasetsState.datasets);
      return;
    }
    dispatch(update({ loading: true }));
    biClient
      .getMeasureDataSets()
      .then((result) => {
        if (result.success) {
          dispatch(update({ datasets: result.data, loaded: true }));
          actions.setDataSets(result.data);
        } else {
          setDatasetError(locale.datasets_loading_error);
        }
      })
      .catch(() => setDatasetError(locale.datasets_loading_error))
      .finally(() => dispatch(update({ loading: false })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartOfAccounts === undefined) {
      dispatchStoreAction(update({ loading: true }));
      biClient
        .getChartOfAccounts()
        .then((result) => {
          if (result.success) {
            dispatchStoreAction(metaDataActions.setChartOfAccounts(result.data));
          } else {
            setDataLoadingError(locale.chart_of_accounts_loading_error);
          }
        })
        .catch(() => setDataLoadingError(locale.chart_of_accounts_loading_error))
        .finally(() => dispatchStoreAction(update({ loading: false })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartOfAccounts]);

  useEffect(() => {
    const filteredDictionaries = getAvailableDimensions(dimensions);

    dispatchCustom({
      type: CustomMeasureActionType.UPDATE_DIMENSIONS,
      payload: { dimensions: filteredDictionaries },
    });
  }, [dimensions]);

  useEffect(() => {
    dispatchCustom({ type: CustomMeasureActionType.UPDATE, payload: { functions } });
  }, [functions]);

  useEffect(() => {
    if (measure !== undefined) {
      const measureData: Partial<CustomMeasureState> = {
        formula: measure.formula,
        caption: measure.caption,
        group: measure.group || defaultGroup,
        calculateBy: toCalculateBy(measure.units),
        formulaNodes: toFormulaNodes(measure.units, dimensions),
        amountType: toAmountType(measure.units),
        isConfigurable: measure.capabilities.isConfigurable,
      };
      if (measureFormat) {
        measureData.format = measureFormat;
      }
      dispatchCustom({ type: CustomMeasureActionType.UPDATE, payload: measureData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure]);

  const actions = useMemo(() => {
    return {
      update: (changes: Partial<CustomMeasureState>) =>
        dispatchCustom({ type: CustomMeasureActionType.UPDATE, payload: changes }),
      setDataSets: (dataSets: MeasureDataset[]) =>
        dispatchCustom({ type: CustomMeasureActionType.SET_DATASETS, payload: dataSets }),
      setRootNode: (rootNode?: INode) =>
        dispatchCustom({ type: CustomMeasureActionType.SET_ROOTNODE, payload: rootNode }),
      updateFormulaNode: (node: FormulaNode, changes: Partial<FormulaNode>) =>
        dispatchCustom({ type: CustomMeasureActionType.UPDATE_FORMULA_NODE, payload: { node, changes } }),
      addConditions: (node: FormulaNode, newConditions: ConditionField[]) =>
        dispatchCustom({ type: CustomMeasureActionType.ADD_CONDITIONS, payload: { node, newConditions } }),
      updateCondition: (node: FormulaNode, condition: ConditionField, changes: Partial<ConditionField>) =>
        dispatchCustom({
          type: CustomMeasureActionType.UPDATE_CONDITION,
          payload: { node, condition, changes },
        }),
      removeCondition: (node: FormulaNode, condition: ConditionField) =>
        dispatchCustom({ type: CustomMeasureActionType.REMOVE_CONDITION, payload: { node, condition } }),
      highlightNode: (token: Token | undefined) =>
        dispatchCustom({ type: CustomMeasureActionType.HIGHLIGHT_NODE, payload: token }),
      functionHovered: (token: Token | undefined) =>
        dispatchCustom({ type: CustomMeasureActionType.FUNCTION_HOVERED, payload: token }),
      setSelectedFormulaParameter: (token: Token | undefined) =>
        dispatchCustom({ type: CustomMeasureActionType.FORMULA_PARAMETER_CLICK, payload: token }),
    };
  }, []);

  return {
    ...state,
    formulaValid,
    chartOfAccounts,
    actions,
    dataLoadingError,
    loading: datasetsState.loading,
    datasetError,
  };
};

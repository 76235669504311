import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { RowField } from "../../../Types";
import { BuilderContext } from "../../types";
import StyleSwitch from "./StyleSwitch";

interface Props {
  field: RowField;
}

export default function TextField({ field }: Props) {
  const { rowsArea } = React.useContext(BuilderContext);

  const onChangeShowTotals = React.useCallback(
    (showTotals: boolean) => {
      const style = !field.config.style ? {} : cloneDeep(field.config.style);
      style.showTotals = showTotals;
      rowsArea.updateItemConfig(field, { style });
    },
    [field, rowsArea]
  );

  const onUpdateFontStyle = React.useCallback(
    (fontStyle: string[]) => {
      const style = !field.config.style ? {} : cloneDeep(field.config.style);
      style.fontStyle = fontStyle;
      rowsArea.updateItemConfig(field, { style });
    },
    [field, rowsArea]
  );

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        borderBottom: "1px dashed #E0E0E0",
      }}
    >
      <Typography variant="subtitle2" fontWeight={500} color={(theme) => theme.palette.text.primary}>
        {field.config.customLabel || field.meta.caption}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          py: ".7rem",
          gap: 1,
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
          }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Typography color={(theme) => theme.palette.text.secondary}>Text</Typography>
            <HorizontalFill />
            <ToggleButtonGroup
              value={field.config.style?.fontStyle}
              sx={{
                ".MuiButtonBase-root": {
                  border: "none",
                  py: 0,
                  px: 0.5,
                },
              }}
              onChange={(_, value) => onUpdateFontStyle(value)}
            >
              <ToggleButton value={"bold"}>
                <FormatBoldIcon />
              </ToggleButton>
              <ToggleButton value={"italic"}>
                <FormatItalicIcon />
              </ToggleButton>
              <ToggleButton value={"underlined"}>
                <FormatUnderlinedIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <StyleSwitch
          label="Show Totals"
          value={field.config.style?.showTotals === true}
          onChange={onChangeShowTotals}
        />
      </Box>
    </Grid>
  );
}

import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { getLinearSettings, isLinearSettings } from "../../contexts/FieldsStateContext.types";
import TypographyInfo from "../TypographyInfo";

interface Props {
  area: SettingsArea;
}

export default function RotateItem({ area }: Props) {
  const { chart: locale } = useLocalization();
  const supported = React.useMemo(() => isLinearSettings(area.settings), [area]);
  const rotated = React.useMemo(() => isRotated(area), [area]);

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <TypographyInfo tooltip={locale.rotated_property_explanation}>
        <Typography color={(theme) => theme.palette.text.secondary}>Rotated</Typography>
      </TypographyInfo>
      <HorizontalFill />
      <Switch checked={rotated} onChange={(_, value) => area.update({ rotated: value })} />
    </Stack>
  );
}

export function isRotated(area: SettingsArea) {
  return getLinearSettings(area.settings)?.rotated === true;
}

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import TuneIcon from "@mui/icons-material/Tune";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import { useDrillDownExport } from "../../../../hooks/drillDownExport";
import { DrillDownState } from "../../../../hooks/drillDownState";
import { useLocalization } from "../../../../hooks/useLocalization";
import WarningIcon from "../../../../icons/WarningIcon";
import { selectDrillDownItems } from "../../../../store/drilldownSlice";
import biClient from "../../../../api/biApi";

interface Props {
  state: DrillDownState;
  onShowColumnsOptions: () => void;
}

export default function ToolBar({ state, onShowColumnsOptions }: Props) {
  const exporting = useDrillDownExport(biClient.requestDrillDownExport);
  const { drilldown: locale } = useLocalization();
  const configuration = useSelector(selectDrillDownItems);

  const exportAllowed = React.useMemo(() => {
    return state.gridState.state.totalCount <= 50000;
  }, [state.gridState.state.totalCount]);

  const exportNotAllowedTooltipMessage = React.useMemo(() => {
    return exportAllowed ? "" : locale.export_max_count_exceeded;
  }, [exportAllowed, locale.export_max_count_exceeded]);

  const exportToExcel = React.useCallback(() => {
    if (!exportAllowed) return;
    const config = state.actions.getDrillDownConfig();
    exporting.requestExport(config);
  }, [state.actions, exporting, exportAllowed]);

  if (!configuration || configuration.length === 0) {
    return <></>;
  }

  return (
    <Grid container sx={{ flexDirection: "row", alignItems: "center", gap: 1.5 }}>
      <HorizontalFill />
      <Tooltip
        title={exportNotAllowedTooltipMessage}
        arrow
        PopperProps={{
          sx: (theme) => ({
            [`& .${tooltipClasses.arrow}`]: {
              color: theme.palette.common.black,
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: theme.palette.common.black,
            },
          }),
        }}
      >
        <Box>
          <LoadingButton
            variant="outlined"
            color="secondary"
            disabled={exporting.exporting || state.gridState.state.loading}
            startIcon={exportAllowed ? <ExitToAppIcon /> : <WarningIcon />}
            loading={exporting.exporting}
            onClick={exportToExcel}
          >
            Export
          </LoadingButton>
        </Box>
      </Tooltip>
      <Button variant="outlined" color="secondary" startIcon={<TuneIcon />} onClick={onShowColumnsOptions}>
        {locale.column_options_label}
      </Button>
    </Grid>
  );
}

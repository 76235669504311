import { Grid, Switch, Typography } from "@mui/material";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";

interface Props {
  label: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export const StyleSwitch = ({ label, value, onChange }: Props) => {
  return (
    <Grid container sx={{ alignItems: "center" }}>
      <Typography color={(theme) => theme.palette.text.secondary}>{label}</Typography>
      <HorizontalFill />
      <Switch checked={value === true} onClick={() => onChange(!value)} />
    </Grid>
  );
};

export default StyleSwitch;

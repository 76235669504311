import { Grid, Typography } from "@mui/material";
import React from "react";
import { BuilderContext } from "../../types";
import StyleSwitch from "./StyleSwitch";

export default function Settings() {
  const { settingsArea } = React.useContext(BuilderContext);

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        borderBottom: "1px dashed #E0E0E0",
        pb: 1,
        gap: 0.5,
      }}
    >
      <Typography variant="subtitle2" fontWeight={500} color={(theme) => theme.palette.text.primary}>
        General settings
      </Typography>
      <StyleSwitch
        label="Hide Blank Rows"
        value={settingsArea.settings.hideBlankRows === true}
        onChange={(newValue) => settingsArea.update({ hideBlankRows: newValue })}
      />
      <StyleSwitch
        label="Hide Empty Columns"
        value={settingsArea.settings.hideEmptyColumns === true}
        onChange={(newValue) => settingsArea.update({ hideEmptyColumns: newValue })}
      />
    </Grid>
  );
}

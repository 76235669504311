import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InlineLoaderSvg from "../../../../../shared/components/inlineLoader/InlineLoaderSvg";
import biClient from "../../../../api/biApi";
import { useLocalization } from "../../../../hooks/useLocalization";
import DataRefreshIcon from "../../../../icons/DataRefreshIcon";
import {
  currentReportActions,
  selectDrillDownCacheValid,
  selectEtag,
  selectSessionId,
} from "../../../../store/currentReportSlice";
import { drillDownActions } from "../../../../store/drilldownSlice";
import { DrillDownConfiguration } from "../../../../store/DrillDownConfigurationState";

interface Props {
  configuration: DrillDownConfiguration;
}
export default function Loader({ configuration }: Props) {
  const { drilldown: locale } = useLocalization();
  const dispatch = useDispatch();

  const sessionId = useSelector(selectSessionId);
  const isCacheValid = useSelector(selectDrillDownCacheValid);
  const [error, setError] = useState<boolean>(false);

  const validationFailed = useDrillDownCacheValidation();

  useEffect(() => {
    if (configuration.info || !configuration.cell.drillDownId || !isCacheValid) return;
    let cancelled = false;
    biClient
      .getDrillDownItem(sessionId, configuration.cell.drillDownId)
      .then((data) => {
        if (cancelled) return;
        if (data?.success === true && data.data) {
          dispatch(drillDownActions.setInfo({ id: configuration.id, info: data.data }));
        } else {
          setError(true);
        }
      })
      .catch(() => {
        if (cancelled) return;
        setError(true);
      });

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration.cell.drillDownId, configuration.id, configuration.info, isCacheValid, sessionId]);

  return (
    <Stack sx={{ px: 3, pb: 3, flex: 1, alignItems: "center", justifyContent: "center", gap: 1 }}>
      {(error || validationFailed) && <ErrorComponent />}
      {!error && !validationFailed && (
        <>
          <Box sx={{ width: 70 }}>
            <InlineLoaderSvg />
          </Box>
          <Typography variant="h6">{locale.loading_drilldown_info}</Typography>
        </>
      )}
    </Stack>
  );
}

function ErrorComponent() {
  const { drilldown: locale } = useLocalization();
  const dispatch = useDispatch();

  const removeAllDrillDowns = useCallback(() => {
    dispatch(drillDownActions.removeAll());
    dispatch(currentReportActions.refresh());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction={"column"} sx={{ alignItems: "center", gap: 1, textAlign: "center" }}>
      <DataRefreshIcon />
      <Typography variant="h6">{locale.drilldown_info.error_caption}</Typography>
      <Typography color={"secondary"}>{locale.drilldown_info.error_text}</Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        startIcon={<CachedRoundedIcon />}
        onClick={removeAllDrillDowns}
      >
        Reload Report
      </Button>
    </Stack>
  );
}

function useDrillDownCacheValidation() {
  const dispatch = useDispatch();
  const sessionId = useSelector(selectSessionId);
  const etag = useSelector(selectEtag);
  const isCacheValid = useSelector(selectDrillDownCacheValid);
  const [error, setError] = useState<boolean>(false);

  const validateCache = useCallback(
    (isCancelled: () => boolean) => {
      if (etag === undefined || isCancelled() === true) return;
      biClient
        .isDrillDownCacheValid(sessionId, etag)
        .then((data) => {
          if (isCancelled()) return;
          if (data.success && !data.data.expired) {
            if (data.data.isCacheValid) {
              dispatch(currentReportActions.setEtag({ etag, cacheValid: true }));
            } else {
              setTimeout(() => validateCache(isCancelled), 1500);
            }
          } else {
            dispatch(currentReportActions.setEtag({ etag, cacheValid: false }));
            setError(true);
          }
        })
        .catch(() => {
          if (isCancelled()) return;
          dispatch(currentReportActions.setEtag({ etag, cacheValid: false }));
          setError(true);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [etag, sessionId]
  );

  useEffect(() => {
    if (isCacheValid && etag !== undefined) return;
    let cancelled = false;
    validateCache(() => cancelled);
    return () => {
      cancelled = true;
    };
  }, [etag, isCacheValid, sessionId, validateCache]);

  return error;
}

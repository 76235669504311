import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import TextFieldExt from "../../../../../shared/components/inputs/TextFieldExt";
import {
  combineValidators,
  minCharactersValidator,
  requiredValidator,
} from "../../../../../shared/utilities/validators";
import { MeasureDescriptor } from "../../../../api/biApi.types";
import { selectMeasureGroups, selectMeasures } from "../../../../store/metaDataSlice";
import { CustomMeasureReturnType } from "./hooks/useCustomMeasure";
import { duplicateMeasureNameValidator } from "./utilities/duplicateMeasureNameValidator";

interface Props {
  measure?: MeasureDescriptor;
  state: CustomMeasureReturnType;
  onError: (error?: string) => void;
}

export const NameField = ({ measure, state, onError }: Props) => {
  const measures = useSelector(selectMeasures);
  const measureGroups = useSelector(selectMeasureGroups);

  useEffect(() => {
    if (state.group === undefined) {
      state.actions.update({ group: measureGroups[0] });
    }
  }, [state.actions, state.group, measureGroups]);

  return (
    <Grid container sx={{ flexDirection: "row" }}>
      <TextFieldExt
        name="name"
        InputProps={{
          size: "small",
          label: "Name",
          variant: "outlined",
          value: state.caption,
          sx: { flex: 1 },
          placeholder: "Set Name...",
        }}
        doValidate={combineValidators(
          requiredValidator,
          minCharactersValidator(3),
          duplicateMeasureNameValidator(measures, measure?.name)
        )}
        onValidated={(_, result) => (result.isValid ? onError() : onError("invalid name"))}
        onValueChanged={(value) => state.actions.update({ caption: value })}
      />
    </Grid>
  );
};

export default NameField;

import { Grid, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { CellDrillDownInfoBase } from "../../../../api/biApi.types";
import { DrillDownState } from "../../../../hooks/drillDownState";
import { selectDimensions } from "../../../../store/metaDataSlice";
import PartContainer from "../../PartContainer";
import Conditions from "../../pivot/table/conditions/Conditions";
import AddCondition from "./AddCondition";
import { DrillDownConfiguration } from "../../../../store/DrillDownConfigurationState";

interface Props {
  state: DrillDownState;
  configuration: DrillDownConfiguration;
  info: CellDrillDownInfoBase;
}

export default function ConditionsPanel({ state, configuration, info }: Props) {
  const dimensions = useSelector(selectDimensions);

  const [detailedView, setDetailedView] = React.useState(true);

  const validDimensions = React.useMemo(
    () => (info?.allocated === true ? dimensions : dimensions.filter((d) => d.glAllowed === true)),
    [info?.allocated, dimensions]
  );

  if (!configuration || !info?.conditions || info.conditions.length === 0) {
    return <></>;
  }

  return (
    <PartContainer
      caption="Conditions"
      captionVariant="subtitle2"
      actions={
        <Stack direction={"row"} alignItems="center" gap={0.5}>
          <Typography>Detailed View</Typography>
          <Switch checked={detailedView} onChange={(_, value) => setDetailedView(value)}></Switch>
        </Stack>
      }
      sx={{ width: "340px", p: "1.3rem", pl: 3 }}
    >
      <Grid container sx={{ pt: 2 }}>
        <Conditions
          fields={state.conditions}
          detailed={detailedView}
          canBeRemoved
          onRemoveFields={state.actions.removeCondition}
          onFieldChanged={state.actions.updateCondition}
        />
      </Grid>
      <AddCondition
        text="Add Condition"
        conditions={state.conditions}
        dimensions={validDimensions}
        onAddField={state.actions.addCondition}
      />
    </PartContainer>
  );
}

import { Grid } from "@mui/material";
import { DrillDownState } from "../../../../hooks/drillDownState";
import { FieldWithOrder, FieldWithSorting } from "../../../../hooks/FieldWithOrder";
import PaginatedGrid, { ColumnsFormatting } from "../PaginatedGrid";
import { Column, DataTypeProvider, Sorting, VirtualTable } from "@devexpress/dx-react-grid";
import { formatDimension } from "../../../../formatters/NumberFormatter";
import { useSelector } from "react-redux";
import React from "react";
import { selectDimensions } from "../../../../store/metaDataSlice";
import { CellDrillDownInfoBase, DimensionDescriptor, ItemDataType } from "../../../../api/biApi.types";
import { DrillDownConfiguration } from "../../../../store/DrillDownConfigurationState";

interface Props {
  state: DrillDownState;
  configuration: DrillDownConfiguration;
  info: CellDrillDownInfoBase;
  onDrillDown?: (row: Record<string, string>) => void;
}

export default function DrillDownGrid({ state, configuration, info, onDrillDown }: Props) {
  const dimensions = useSelector(selectDimensions);

  const columnsAlignment = React.useMemo(
    () => getColumnsAlignment(state.columns, dimensions),
    [state.columns, dimensions]
  );

  const ColumnsFormatter = React.useCallback(
    (props: DataTypeProvider.ValueFormatterProps) => {
      const field = state.fields.find((f) => f.field.name === props.column.name);

      if (field !== undefined) {
        const formattedValue = formatDimension(field.field, props.value);
        return formattedValue;
      }
      return <>{props.value}</>;
    },
    [state.fields]
  );

  const columnFormatters = React.useMemo(() => {
    const numericColumns = state.fields.map((c) => c.field.name);

    return {
      numeric: { columns: numericColumns, formatterComponent: ColumnsFormatter },
    } as ColumnsFormatting;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.fields]);

  const updateColumnsOrder = React.useCallback(
    (names: string[]) => {
      const newOrder = rebuildColumnsOrder(names, state.fields);
      state.actions.changeSelectedFields(newOrder);
    },
    [state.fields, state.actions]
  );

  const updateColumnsSorting = React.useCallback(
    (sorting: Sorting[]) => {
      const newSorting = rebuildColumnsSorting(sorting, dimensions);
      state.actions.changeFieldSorting(newSorting);
    },
    [dimensions, state.actions]
  );

  const handleCellClick = React.useCallback(
    (row: Record<string, string>) => {
      const entryNo = row["EntryNo"];
      if (entryNo !== undefined) onDrillDown?.call(null, row);
    },
    [onDrillDown]
  );

  if (!configuration || !info.conditions || info.conditions.length === 0) {
    return <></>;
  }

  return (
    <Grid container sx={{ flex: 1, position: "relative" }}>
      <Grid
        item
        sx={{
          display: "flex",
          flex: 1,
          position: "absolute",
          overflow: "auto",
          width: "100%",
          height: "100%",
        }}
      >
        <PaginatedGrid
          columns={state.columns}
          columnsAlignment={columnsAlignment}
          columnsFormatting={columnFormatters}
          sorting={state.sorting}
          state={state.gridState.state}
          getRowId={getRowId}
          supportDrillDown={!info?.allocated}
          getRemoteRows={state.gridState.getRemoteRows}
          loadData={state.gridState.loadData}
          onColumnsOrderChange={updateColumnsOrder}
          onColumnsSortingChange={updateColumnsSorting}
          onCellClicked={handleCellClick}
        />
      </Grid>
    </Grid>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getRowId(row: any) {
  return row["EntryNo"];
}

function rebuildColumnsSorting(sorting: Sorting[], allFields: DimensionDescriptor[]) {
  const newSorting = sorting
    .map((sort, index) => {
      const field = allFields.find((f) => f.name === sort.columnName);
      if (field) {
        const sortField = {
          field,
          order: index,
          sortAsc: sort.direction === "asc",
        } as FieldWithSorting;
        return sortField;
      }
      return undefined;
    })
    .filter((field): field is FieldWithSorting => !!field);
  return newSorting;
}

function rebuildColumnsOrder(names: string[], fields: FieldWithOrder[]) {
  const newOrder = names
    .map((name, index) => {
      const field = fields.find((f) => f.field.name === name);
      if (field) {
        field.order = index;
        return field;
      }
      return undefined;
    })
    .filter((field): field is FieldWithOrder => !!field);
  return newOrder;
}
function getColumnsAlignment(columns: Column[], allFields: DimensionDescriptor[]) {
  return columns
    .filter((c) => {
      const field = allFields.find((f) => f.name === c.name);
      return field && field.type === ItemDataType.Numeric;
    })
    .map((c) => ({ columnName: c.name, align: "right" }) as VirtualTable.ColumnExtension);
}

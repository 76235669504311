import { Column, Sorting } from "@devexpress/dx-react-grid";
import objectHash from "object-hash";
import React from "react";
import { useSelector } from "react-redux";
import useDebounce from "../../shared/hooks/useDebounce";
import { MeasureUnitTable } from "../../shared/reporting/api/biClient.types";
import cloneDeep from "../../shared/utilities/cloneDeep";
import { generateGuid } from "../../shared/utilities/generateGuid";
import biClient from "../api/biApi";
import { CellDrillDownInfoBase, ColumnSorting, DrillDownDataRequest } from "../api/biApi.types";
import { AreaItemType, ConditionField } from "../components/builder/Types";
import { getValidConditionDescriptors } from "../components/builder/common/utilities/getValidConditions";
import { selectDimensions } from "../store/metaDataSlice";
import { fieldSateConfigurationInitialState, fieldStateActions, fieldStateReducer } from "./fieldsState";
import { PaginatedGridActionType, PaginatedGridState, initialState, reducer } from "./paginatedGridState";
import { useLocalization } from "./useLocalization";
import { FieldWithOrder, FieldWithSorting } from "./FieldWithOrder";
import { sortOrderedFields } from "./sortOrderedFields";

export function useDrillDownState(info: CellDrillDownInfoBase) {
  const dimensions = useSelector(selectDimensions);
  const [gridState, dispatch] = React.useReducer(reducer, initialState);
  const [fieldState, fieldStateDispatch] = React.useReducer(fieldStateReducer, fieldSateConfigurationInitialState);

  const { common: locale } = useLocalization();

  const [selectedFields, setSelectedFields] = React.useState<FieldWithOrder[]>([]);
  const [sortedFields, setSortedFields] = React.useState<FieldWithSorting[]>([]);
  const [error, setError] = React.useState<string>();

  const gridStateRef = React.useRef(gridState);
  const conditionsRef = React.useRef(fieldState.conditions);

  React.useEffect(() => {
    const copied = info.conditions
      .map((c) => {
        const meta = dimensions.find((d) => d.name === c.dimensionName);
        if (meta === undefined) return undefined;
        const clone = cloneDeep(c);
        const field: ConditionField = {
          meta,
          areaItemType: AreaItemType.CONDITIONS,
          config: {
            guid: generateGuid(),
            filter: clone,
          },
        };
        return field;
      })
      ?.filter((f): f is ConditionField => !!f);

    if (copied === undefined) return;

    setConditions(copied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.conditions]);

  React.useEffect(() => {
    conditionsRef.current = fieldState.conditions;
  }, [fieldState.conditions]);

  React.useEffect(() => {
    gridStateRef.current = gridState;
  }, [gridState]);

  const columns = React.useMemo(() => {
    return selectedFields
      .sort(sortOrderedFields)
      .map((sf) => ({ name: sf.field.name, title: sf.field.caption }) as Column);
  }, [selectedFields]);

  const sorting = React.useMemo(() => {
    return sortedFields
      .sort(sortOrderedFields)
      .map((sf) => ({ columnName: sf.field.name, direction: sf.sortAsc === true ? "asc" : "desc" }) as Sorting);
  }, [sortedFields]);

  const filters = React.useMemo(() => getValidConditionDescriptors(fieldState.conditions), [fieldState.conditions]);
  const conditionsHash = React.useMemo(() => objectHash(filters), [filters]);
  const columnsHash = React.useMemo(() => objectHash(columns.map((c) => c.name).sort()), [columns]);
  const sortingHash = React.useMemo(() => objectHash(sortedFields), [sortedFields]);

  const getDrillDownConfig = React.useCallback(() => {
    const cols = columns.map((c) => ({ dimensionName: c.name }));
    const sorts = sorting.map(
      (s) => ({ dimensionName: s.columnName, sortAsc: s.direction === "asc" }) as ColumnSorting
    );
    const dataRequest: DrillDownDataRequest = {
      columns: cols,
      conditions: filters,
      calcTotalCount: true,
      sortingColumns: sorts,
      skip: 0,
      take: 0,
      allocated: info.allocated === true,
      table: info.table || MeasureUnitTable.Gl,
    };

    return dataRequest;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, sorting, conditionsHash]);

  const drillDownConfigRef = React.useRef(getDrillDownConfig());

  React.useEffect(() => {
    drillDownConfigRef.current = getDrillDownConfig();
  }, [getDrillDownConfig]);

  React.useEffect(() => {
    dispatch({
      type: PaginatedGridActionType.UPDATE_ROWS,
      payload: { ...initialState },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionsHash, columnsHash]);

  React.useEffect(() => {
    dispatch({
      type: PaginatedGridActionType.UPDATE_ROWS,
      payload: { ...initialState, totalCount: gridState.totalCount },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingHash]);

  const setConditions = React.useCallback((fields: ConditionField[]) => {
    fieldStateDispatch(fieldStateActions.setConditions(fields));
  }, []);
  const addConditions = React.useCallback((fields: ConditionField[]) => {
    fieldStateDispatch(fieldStateActions.addConditions(fields));
  }, []);
  const addCondition = React.useCallback((field: ConditionField) => {
    fieldStateDispatch(fieldStateActions.addConditions([field]));
  }, []);
  const updateCondition = React.useCallback((field: ConditionField, changes: Partial<ConditionField>) => {
    fieldStateDispatch(fieldStateActions.updateCondition({ field, changes }));
  }, []);
  const removeCondition = React.useCallback((field: ConditionField) => {
    fieldStateDispatch(fieldStateActions.removeCondition(field));
  }, []);

  const changeSelectedFields = React.useCallback(
    (fields: FieldWithOrder[]) => {
      setSelectedFields([...fields]);
    },
    [setSelectedFields]
  );

  const changeFieldSorting = React.useCallback(
    (fields: FieldWithSorting[]) => {
      setSortedFields([...fields]);
    },
    [setSortedFields]
  );

  const getRemoteRows = (requestedSkip: number, take: number) => {
    dispatch({ type: PaginatedGridActionType.START_LOADING, payload: { requestedSkip, take } as PaginatedGridState });
  };

  const loadData = () => {
    const { requestedSkip, take, lastQuery, loading } = gridState;

    const query = { skip: requestedSkip, take } as PaginatedGridState;
    if ((query.skip !== lastQuery?.skip || query.take !== lastQuery.take) && !loading) {
      dispatch({
        type: PaginatedGridActionType.UPDATE_REQUEST,
        payload: { loading: true, lastQuery: query },
      });

      setError(undefined);
      const body = { ...getDrillDownConfig(), ...query };
      biClient.getDrillDownData(
        body,
        (resp) => {
          if (resp.success) {
            dispatch({
              type: PaginatedGridActionType.UPDATE_ROWS,
              payload: {
                skip: requestedSkip,
                rows: resp.data.data || [],
                totalCount: resp.data.totalCount,
              },
            });
          }
        },
        () => {
          setError(locale.calculation_error);
        }
      );
    }
  };

  const doLoadData = useDebounce(loadData, 10);

  return {
    conditions: fieldState.conditions,
    fields: selectedFields,
    columns,
    sorting,
    sortedFields,
    actions: {
      setConditions,
      addCondition,
      updateCondition,
      addConditions,
      removeCondition,
      changeSelectedFields,
      changeFieldSorting,
      getDrillDownConfig,
    },
    gridState: {
      error,
      state: gridState,
      getRemoteRows,
      loadData: doLoadData,
    },
  };
}

export type DrillDownState = ReturnType<typeof useDrillDownState>;
